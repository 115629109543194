import { RadioButtonGroupProps } from '@ingka/radio-button-group';
import { useTranslation } from 'react-i18next';
import { NotificationChannel } from 'shared-frontend';
import { CustomerReturn } from '../../../models';
import { CustomerReturnView } from '../../../models/GetCustomerReturnResponse';
import {
  useGetAppState,
  useSetPreferredCommunicationMethod,
} from '../../../store/features/appStateSlice/appStateSlice';
import { isViewReturn } from '../../../store/features/appStateSlice/utils';
import {
  useGetSelectedReturnMethodState,
} from '../../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';

type CommunicationOptions = {
  label: string;
  value: NotificationChannel;
}[];

type HookReturn = {
  communicationOptionsErrorMessage?: string;
  hasSelectedReturnMethod: boolean;
  radioOptions: RadioButtonGroupProps['list'];
  showMarketConfigError: boolean;
  viewModeLabel?: string;
};

export function usePreferredCommunicationOptions(): HookReturn {
  const { t } = useTranslation();
  const selectedReturnMethod = useGetSelectedReturnMethodState('selectedReturnMethod');
  const customerReturn = useGetAppState('customerReturn') as CustomerReturn | CustomerReturnView;
  const isViewMode = isViewReturn(customerReturn);
  const setPreferredCommunicationMethod = useSetPreferredCommunicationMethod();
  const preferredCommunicationMethod = useGetAppState('preferredCommunicationMethod');

  const { deliveryInfo: { email, mobile, phone } } = customerReturn.customer;
  const cellularOption = mobile || phone;
  const hasSelectedReturnMethod = !!selectedReturnMethod;

  const hookReturn: HookReturn = {
    hasSelectedReturnMethod,
    radioOptions: [],
    showMarketConfigError: false,
  };

  if (isViewMode) {
    const value = customerReturn.customer.preferredCommunicationMethod;
    hookReturn.viewModeLabel = (value === 'EMAIL' ? email : cellularOption);

    return hookReturn;
  }

  if (!hasSelectedReturnMethod) {
    return hookReturn;
  }

  const { notificationChannels } = selectedReturnMethod;
  if (!notificationChannels?.length) {
    hookReturn.showMarketConfigError = true;
    return hookReturn;
  }

  const communicationOptions: CommunicationOptions = [];
  if (email && notificationChannels.includes('EMAIL')) {
    communicationOptions.push({ label: email, value: 'EMAIL' });
  }
  if (cellularOption && notificationChannels.includes('SMS')) {
    communicationOptions.push({ label: cellularOption, value: 'SMS' });
  }

  if (communicationOptions.length === 0) {
    hookReturn.communicationOptionsErrorMessage = notificationChannels[0] === 'EMAIL'
      ? t('customer-details-data-card.missingEmailError')
      : t('customer-details-data-card.missingMobileOrPhoneError');

    return hookReturn;
  }

  hookReturn.radioOptions = communicationOptions.map(({ label, value }) => ({
    checked: preferredCommunicationMethod === value,
    id: value,
    label,
    name: 'preferredCommunicationOptionsRadios',
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedOption = e.target.value as NotificationChannel;
      setPreferredCommunicationMethod(selectedOption);
    },
    value,
  }));

  return hookReturn;
}
