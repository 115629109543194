import { ModalBody } from '@ingka/modal';
import {
  ConditionOfGoods,
  ConditionOfGoodsProps,
  SelectionState,
} from '@resolutions/condition-of-goods';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetArticleInSidebar,
  useSetConditionOfGoods,
} from '../../../store/features/OTCSlice/OTCSlice';

export function OTCConditionOfGoodsBody() {
  const { t } = useTranslation();
  const translations = t('resolutions:condition-of-goods-picker', {
    returnObjects: true,
  }) as ConditionOfGoodsProps['translations'];
  const articleInSidebar = useGetArticleInSidebar();
  const setConditionOfGoods = useSetConditionOfGoods();

  const isBulk = articleInSidebar === undefined;
  const { canBeAssembled, conditionOfGoods, issue } = articleInSidebar ?? {};
  const [selection, setSelection] = useState<SelectionState | undefined | null>(
    isBulk ? undefined : conditionOfGoods,
  );

  const onSelection: ConditionOfGoodsProps['onSelection'] = (newSelection) => {
    setSelection(newSelection);
    setConditionOfGoods(newSelection);
  };

  return (
    <ModalBody>
      <ConditionOfGoods
        canBeAssembled={isBulk || canBeAssembled}
        isAssembled={selection?.isAssembled}
        issueCategory={isBulk ? undefined : issue?.category}
        onSelection={onSelection}
        packageCondition={selection?.packageCondition}
        showBulkMessage={isBulk}
        translations={translations}
      />
    </ModalBody>
  );
}
