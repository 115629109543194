import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isAfter } from 'date-fns';
import { NotificationChannel } from 'shared-frontend';
import { useLamnaDispatch, useLamnaSelector } from '../../app/hooks';
import {
  InitialCreateModePayload,
  setInitialCreateMode,
  ArticlesWithIncorrectUnit,
  articlesWithIncorrectUnit,
  timeWindowSet,
} from '../standAloneActions/extraReducersActions';
import { refreshAuth, RefreshAuth } from '../standAloneActions/saljaUser';
import { addApiMatchers } from './apiMatchers';
import { AppStateInterface, InitialViewModePayload } from './utils';
import { promptSlice } from '../prompt/promptSlice';
import { InitErrorActionPayload } from '../../../components/InitErrorMessage/models';

export const initialState: AppStateInterface = {
  accessToken: 'initial',
  coworkerAuth: {
    expiresOn: null,
    extExpiresOn: null,
    status: 'idle',
    username: null,
  },
  articlesWithIncorrectUnit: null,
  customerReturn: null,
  failedPickupReason: null,
  features: [],
  getReturnIdentifierParam: null,
  isAuthenticated: false,
  preferredCommunicationMethod: 'EMAIL',
  rescheduleAvailableTimeWindows: null,
  returnId: null,
  returnReference: null,
  selfServiceViewURL: null,
  showSaveCustomerReturnButton: false,
  showStockCorrection: false,
  statusViewModel: null,
  userId: null,
};

const FEATURE = {
  showPaidByTsp: 'showPaidByTsp',
} as const;

export type AvailableFeatures = keyof typeof FEATURE;

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setReturnReference: (state, action) => {
      state.returnReference = action.payload;
    },
    setPreferredCommunicationMethod: (
      state: AppStateInterface,
      action: PayloadAction<NotificationChannel>,
    ) => {
      state.preferredCommunicationMethod = action.payload;
    },
    setShowStockCorrection: (state: AppStateInterface, action: PayloadAction<boolean>) => {
      state.showStockCorrection = action.payload;
    },
    setFailedPickupReason: (state: AppStateInterface, action: PayloadAction<'FAILED_TSP' | 'FAILED_CUSTOMER' | null>) => {
      state.failedPickupReason = action.payload;
    },
    setInitialViewMode: (
      state: AppStateInterface,
      action: PayloadAction<InitialViewModePayload>,
    ) => {
      state.accessToken = action.payload.accessToken;
      state.getReturnIdentifierParam = action.payload.getReturnIdentifierParam;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.userId = action.payload.userId;
      state.initError = undefined;
    },
    setCoworkerAuth: (
      state: AppStateInterface,
      action: PayloadAction<RefreshAuth>,
    ) => {
      const {
        accessToken,
        expiresOn,
        extExpiresOn,
        status,
        username,
      } = action.payload;

      state.accessToken = accessToken;
      state.isAuthenticated = status === 'succeeded';
      state.coworkerAuth = {
        expiresOn,
        extExpiresOn,
        status: status || 'idle',
        username,
      };
    },
    setInitError: (
      state: AppStateInterface,
      action: PayloadAction<InitErrorActionPayload>,
    ) => {
      state.initError = action.payload;
      state.accessToken = 'failed';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setInitialCreateMode, (
        state: AppStateInterface,
        action: PayloadAction<InitialCreateModePayload>,
      ) => {
        state.accessToken = action.payload.accessToken;
        state.customerReturn = action.payload.customerReturn;
        state.isAuthenticated = action.payload.isAuthenticated;
        state.userId = action.payload.userId;

        const { customerReturn } = action.payload;
        if (!customerReturn) return;
        state.initError = undefined;
        const { customer: { deliveryInfo: { email, mobile, phone } } } = customerReturn;
        if (email) {
          state.preferredCommunicationMethod = 'EMAIL';
        } else if (mobile || phone) {
          state.preferredCommunicationMethod = 'SMS';
        }
      })
      .addCase(timeWindowSet, (state, action) => {
        state.showSaveCustomerReturnButton = Boolean(action.payload.timeWindowHasChangedInViewMode);
      })
      .addCase(promptSlice.actions.closePrompt, (state, action) => {
        if (action.payload === 'retry-reschedule') {
          state.showSaveCustomerReturnButton = false;
        }
      })
      .addCase(articlesWithIncorrectUnit, (
        state: AppStateInterface,
        action: PayloadAction<ArticlesWithIncorrectUnit>,
      ) => {
        state.articlesWithIncorrectUnit = action.payload;
      })
      .addCase(refreshAuth.fulfilled, (
        state: AppStateInterface,
        action: PayloadAction<RefreshAuth>,
      ) => {
        state.coworkerAuth.status = 'pending';

        if (action.payload?.status === 'failed') {
          state.coworkerAuth.status = 'failed';
          return;
        }

        const { accessToken, ...payloadWithoutAccessToken } = action.payload;
        state.accessToken = accessToken;
        state.coworkerAuth = payloadWithoutAccessToken;
      });
    addApiMatchers(builder);
  },
});

export const useGetAppState = <Key extends keyof AppStateInterface>(key: Key) => useLamnaSelector(
  (state) => state.appState[key],
);

export const useGetShowSaveCustomerReturnButton = () => useLamnaSelector(
  (state) => {
    const {
      showSaveCustomerReturnButton,
      rescheduleAvailableTimeWindows,
    } = state.appState;
    const originalTime = rescheduleAvailableTimeWindows?.proposedTimeWindowId;
    const rescheduleTime = state.selectedReturnMethod
      ?.selectedReturnMethod?.timeWindow?.timeWindowId;
    const timeWindowIsOriginal = originalTime === rescheduleTime;
    return showSaveCustomerReturnButton && !timeWindowIsOriginal;
  },
);

export const useHasStockCorrectionItems = () => {
  const customerReturn = useGetAppState('customerReturn');
  return customerReturn?.items.some((item) => 'receiving' in item) ?? false;
};

export const useToggleStockCorrection = () => {
  const dispatch = useLamnaDispatch();
  const showStockCorrection = useGetAppState('showStockCorrection');
  return () => (
    dispatch(appStateSlice.actions.setShowStockCorrection(!showStockCorrection))
  );
};
export const useSetPreferredCommunicationMethod = () => {
  const dispatch = useLamnaDispatch();
  return (preferredCommunicationMethod: NotificationChannel) => (
    dispatch(appStateSlice.actions.setPreferredCommunicationMethod(preferredCommunicationMethod))
  );
};
export const useSetFailedPickupReason = () => {
  const dispatch = useLamnaDispatch();
  return (reason: 'FAILED_TSP' | 'FAILED_CUSTOMER' | null) => (
    dispatch(appStateSlice.actions.setFailedPickupReason(reason))
  );
};
export const useRefreshUserToken = () => {
  const dispatch = useLamnaDispatch();
  const expiration = useGetAppState('coworkerAuth').expiresOn;
  return () => {
    if (expiration && isAfter(new Date(), new Date(expiration))) {
      dispatch(refreshAuth());
    }
  };
};

export const useGetEnabledFeature = (feature: AvailableFeatures) => {
  const activeFeatures = useGetAppState('features');
  return activeFeatures.includes(feature);
};
