import { StatusViewModel } from 'shared-frontend';
import type { AppEndPointBuilder } from '../apiSlice';
import { ViewModeApplicableActions, FailedPickupReason } from '../../../../models';
import { IsPaid, WithHoldUntilPaid } from '../../../../models/SamsPostMessage';

export type CancelReturnParams = {
  countryCode: Uppercase<string>;
  postBody: {
    reasonCode?: FailedPickupReason;
    updatedBy: string;
  };
  returnId: string;
};

export type CancelReturnTransportOption = {
  deliveryMethodId: string | undefined;
};

export type CancelReturnTransportHoldUntilPayment<Paid extends boolean> =
CancelReturnTransportOption & {
  holdUntilPaid: Paid extends true ? {
    enabled: true;
    amount: number;
    currencyCode: string;
  } : {
    enabled: true;
  };
};

export type CancelReturnSuccessResponse<
HoldUntilPaidEnabled extends boolean = false, Paid extends boolean = false,
> = {
  statusViewModel: StatusViewModel,
  actions: ViewModeApplicableActions[]
  returnOption: HoldUntilPaidEnabled extends true
    ? CancelReturnTransportHoldUntilPayment<Paid> : CancelReturnTransportOption
};

export function isHoldUntilPaidEnabled(
  response: CancelReturnSuccessResponse<WithHoldUntilPaid, IsPaid>,
): response is CancelReturnSuccessResponse<true> {
  return 'holdUntilPaid' in response.returnOption && response.returnOption.holdUntilPaid.enabled;
}

export function isHoldUntilPaidEnabledAndPaid(
  response: CancelReturnSuccessResponse<WithHoldUntilPaid, IsPaid>,
): response is CancelReturnSuccessResponse<true, true> {
  return 'holdUntilPaid' in response.returnOption && 'amount' in (response.returnOption.holdUntilPaid ?? {});
}

const CANCEL = 'cancel';
const CANCEL_FAILED = 'cancel-failed';
type CancelType = typeof CANCEL | typeof CANCEL_FAILED;

const getBaseURL = (countryCode: string, returnId: string, api: CancelType) => (`/v2/${countryCode}/return-views/cw/${returnId}/actions/${api}`);

export const getCancelApi = (config: CancelReturnParams) => {
  const { countryCode, postBody, returnId } = config;
  const api = postBody.reasonCode ? CANCEL_FAILED : CANCEL;
  return {
    url: getBaseURL(countryCode, returnId, api),
    method: 'POST',
    body: postBody,
  };
};

export const cancelReturnBuilder = (builder: AppEndPointBuilder) => builder
  .mutation<CancelReturnSuccessResponse, CancelReturnParams>({
  extraOptions: { maxRetries: 0 },
  query: ({ countryCode, postBody, returnId }) => {
    const { url, method, body } = getCancelApi({ countryCode, postBody, returnId });
    return {
      body,
      method,
      url,
    };
  },
});
