import { useMarketLocale } from '../useMarketLocale';

type PriceInfo = {
  currencyCode: string;
  quantity?: number;
  price: number | 'N/A';
};

export type UseItemPriceProps = PriceInfo;

const currencyFormatter = (
  locale: string,
  currencyCode: string,
) => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency: currencyCode,
});

const getFormattedPrice = (
  locale: string,
  currencyCode: string,
  amount: number,
) => {
  try {
    return currencyFormatter(locale, currencyCode).format(amount);
  } catch {
    return amount;
  }
};

export const getPriceFormatValues = (
  locale: string,
  currencyCode: string,
  amount: number,
) => {
  try {
    const priceI18 = currencyFormatter(locale, currencyCode).formatToParts(amount);
    const int = priceI18.filter((part) => part.type === 'integer').reduce((acc, part) => acc + part.value, '');
    const decimal = priceI18.find((part) => part.type === 'decimal')?.value;
    const fraction = priceI18.find((part) => part.type === 'fraction')?.value;

    return {
      currency: priceI18.find((part) => part.type === 'currency')?.value,
      price: `${int}${decimal}${fraction}`,
    };
  } catch {
    return null;
  }
};

export function useFormatItemPrice({
  currencyCode,
  quantity,
  price,
}: UseItemPriceProps) {
  const { locale } = useMarketLocale();
  if (price === 'N/A' || Number.isNaN(price)) {
    return {
      formattedPriceTotal: 'N/A',
      formattedPriceUnit: 'N/A',
      currencySymbol: null,
      priceUnit: 'N/A',
      priceTotal: 'N/A',
    };
  }
  const totalPrice = (quantity ?? 1) * price;
  const unitPrice = price;

  const formattedPriceTotal = getFormattedPrice(locale, currencyCode, totalPrice);
  const formattedPriceUnit = getFormattedPrice(locale, currencyCode, unitPrice);
  const priceFormatValuesTotal = getPriceFormatValues(locale, currencyCode, totalPrice);
  const priceFormatValuesUnit = getPriceFormatValues(locale, currencyCode, unitPrice);

  return {
    formattedPriceTotal,
    formattedPriceUnit,
    currencySymbol: priceFormatValuesUnit?.currency ?? null,
    priceUnit: priceFormatValuesUnit?.price ?? price,
    priceTotal: priceFormatValuesTotal?.price ?? totalPrice,
  };
}
