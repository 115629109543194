import { createAsyncThunk } from '@reduxjs/toolkit';
import getAuth, { GetUserResponse, GetUserStatus } from '../../../auth';

type User = {
  accessToken: string | null;
  expiresOn: string | null;
  extExpiresOn: string | null;
  username: string | null;
};
type RefreshAuthStatus = GetUserStatus | 'idle' | 'pending';
export type RefreshAuth = User & { status: RefreshAuthStatus };

export const refreshAuth = createAsyncThunk(
  'refreshAuth',
  async (): Promise<RefreshAuth> => {
    const saljaUser: GetUserResponse | null = await getAuth();
    const {
      accessToken, account, expiresOn, extExpiresOn, status,
    } = saljaUser;
    return {
      accessToken,
      expiresOn: expiresOn?.toUTCString() ?? null,
      extExpiresOn: extExpiresOn?.toUTCString() ?? null,
      status,
      username: account.username,
    };
  },
);
