import { useTranslation } from 'react-i18next';
import TextArea from '@ingka/text-area';
import { useSetArticleComment } from '../../../store/features/OTCSlice/OTCSlice';
import { PopItem } from '../../../store/features/OTCSlice/reducerUtils';
import { useOnUnmount } from '../../../hooks/useOnUnmount/useOnUnmount';

export function ItemNoteGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const setArticleComment = useSetArticleComment();

  const [comment, setComment] = useOnUnmount({
    initialValue: item?.comment ?? '',
    onUnmount: (liveValue) => setArticleComment(liveValue, {
      popId: item.popId as string,
      itemLineId: item.itemLineId,
    }),
  });

  return (
    <div className="w-full">
      <TextArea
        data-testid="otc-item-details-note"
        id="otc-item-details-notes"
        label={t('otc-item-details-sidebar.item-note-group.label')}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
    </div>
  );
}
