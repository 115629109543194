import { ItemAdded } from '@resolutions/item-picker-contract';
import {
  mapFetchedProductToStateItem,
  removeDots,
  StatePop,
} from '../../../store/features/OTCSlice/reducerUtils';

export const getProductFromPops = (
  pops: StatePop[],
  articleId: string,
) => {
  let matchingPopItem: ItemAdded | null = null;

  for (let i = 0; i < pops.length; i += 1) {
    const pop = pops[i];
    const fetchedPopProducts = pop.fetchedResponse?.products;

    if (fetchedPopProducts) {
      for (let j = 0; j < fetchedPopProducts.length; j += 1) {
        const { product } = fetchedPopProducts[j];
        if (removeDots(product.productNumber) === articleId) {
          const createPopItem = (deliveryReference?: string) => ({
            item: mapFetchedProductToStateItem({
              deliveryReference,
              itemLineId: `${j + 1}`,
              product,
            }),
            purchaseId: pop.id,
          });

          const articleInItems = pop.items.find(
            (item) => item.itemNo === articleId
              && item.itemLineId === product.lineId,
          );
          const isPopEmpty = pop.items.length === 0;
          if (isPopEmpty || !articleInItems) {
            matchingPopItem = createPopItem();
            break;
          }

          const maxQuantity = product.quantity;
          if (articleInItems.quantity + 1 <= maxQuantity!) {
            matchingPopItem = createPopItem(articleInItems.deliveryReference);
            break;
          }
        }
      }
    }

    if (matchingPopItem) {
      break;
    }
  }

  return matchingPopItem;
};
