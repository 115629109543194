import InlineMessage from '@ingka/inline-message';
import RadioButtonsGroup from '@ingka/radio-button-group';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { usePreferredCommunicationOptions } from './usePreferredCommunicationOptions';

function InlineErrorMessage({ message }: { message: string }) {
  return (
    <InlineMessage
      body={message}
      className="break-normal"
      ssrIcon={informationCircle}
      subtle
      variant="negative"
    />
  );
}

function PreferredCommunicationOptions() {
  const { t } = useTranslation();
  const {
    communicationOptionsErrorMessage,
    hasSelectedReturnMethod,
    radioOptions,
    showMarketConfigError,
    viewModeLabel,
  } = usePreferredCommunicationOptions();

  if (viewModeLabel) {
    return (
      <Text bodySize="m" data-testid="view-mode-communication-method" tagName="span">
        {viewModeLabel}
      </Text>
    );
  }

  if (!hasSelectedReturnMethod) {
    return <InlineErrorMessage message={t('return-method-card.returnMethod.noMethodSelected')} />;
  }

  if (showMarketConfigError) {
    return <InlineErrorMessage message={t('customer-details-data-card.incorrectMarketConfig')} />;
  }

  if (communicationOptionsErrorMessage) {
    return <InlineErrorMessage message={communicationOptionsErrorMessage} />;
  }

  return (
    <RadioButtonsGroup
      className="[&_.radio:not(:last-child)]:mb-2 [&_.radio_input[type=radio]:disabled_~_.radio\_\_label_label]:!text-lightest"
      disabled={radioOptions.length === 1}
      list={radioOptions}
    />
  );
}

export default PreferredCommunicationOptions;
