import InlineMessage from '@ingka/inline-message';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import {
  useFormatTspAndTimeWindowForUI,
  useGetExchangeResolutionData,
} from './useExchangeResolution';

export function ExchangeResolution() {
  const { t } = useTranslation();
  const exchangeResolution = useGetExchangeResolutionData();
  const formatUIStrings = useFormatTspAndTimeWindowForUI();
  if (!exchangeResolution) return null;

  const { orderNumber, ...formatUIStringProps } = exchangeResolution;
  const { deliveryDateTimeStr, tspStr } = formatUIStrings(formatUIStringProps);

  return (
    <InlineMessage
      body={(
        <>
          <Text tagName="span" className="block">{t('exchangeResolution.description')}</Text>
          <Text tagName="span" className="block !text-dark font-bold mt-4">
            {t('exchangeResolution.additionalInfo')}
          </Text>
          <Text tagName="span" className="block">
            {t('exchangeResolution.outgoingOrder', { orderNumber })}
          </Text>
          {deliveryDateTimeStr && (
            <Text data-testid="delivery-date-time" tagName="span" className="block">
              {t('exchangeResolution.deliveryDate', { deliveryDateTimeStr })}
            </Text>
          )}
          {tspStr && (
            <Text data-testid="tsp-data" tagName="span" className="block">
              {tspStr}
            </Text>
          )}
        </>
      )}
      className="my-4 m:w-fit"
      data-testid="exchange-resolution"
      title={t('exchangeResolution.title')}
      variant="cautionary"
      headingLevel="2"
    />
  );
}
