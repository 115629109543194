import { PopItem } from '../store/features/OTCSlice/reducerUtils';

export function getUnitPrice(itemPrice?: PopItem['price']) {
  const priceInclTax = itemPrice?.unit?.paidPrice?.priceInclTax ?? null;
  const priceExclTax = itemPrice?.unit?.paidPrice?.priceExclTax ?? null;
  const priceChange = itemPrice?.unit?.priceChange?.value ?? null;

  const originalPrice = (priceInclTax || priceExclTax) ?? ('N/A' as const);
  let hasPriceChange: boolean;
  let priceValue: number | 'N/A';

  if (priceChange !== null && priceChange !== priceInclTax && priceChange !== priceExclTax) {
    priceValue = priceChange;
    hasPriceChange = true;
  } else {
    priceValue = originalPrice;
    hasPriceChange = false;
  }

  return {
    unitPrice: priceValue.toString(),
    initialUnitPrice: originalPrice.toString(),
    hasPriceChange,
  };
}
