import Choice, { ChoiceItem } from '@ingka/choice';
import InlineMessage from '@ingka/inline-message';
import Loading, { LoadingBall } from '@ingka/loading';
import { ModalBody } from '@ingka/modal';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { useTranslation } from 'react-i18next';
import { useGetPrinters } from '../../../hooks/useGetPrinters';
import { useGetOTCState, useSetSelectedPrinter } from '../../../store/features/OTCSlice/OTCSlice';
import { useSetUserPrinterInLocalStorage } from '../../../hooks/useLocalStoragePrinters/useLocalStoragePrinters';

export function OTCSelectRecoveryLabelPrinterBody() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetPrinters();
  const selectedPrinter = useGetOTCState('selectedPrinter');
  const setSelectedPrinter = useSetSelectedPrinter();
  const setUserPrinterInLocalStorage = useSetUserPrinterInLocalStorage();

  if (isLoading) {
    return (
      <ModalBody>
        <Loading data-testid="loader" text={t('commonly-reused.loading')}>
          <LoadingBall />
        </Loading>
      </ModalBody>
    );
  }

  const { printers } = data ?? {};
  if (!printers || printers.length === 0) {
    return (
      <ModalBody>
        <InlineMessage
          body={t('otc-select-recovery-label-printer-sidebar.fetchPrintersErrorMessage')}
          className="break-normal"
          ssrIcon={informationCircle}
          subtle
          variant="negative"
        />
      </ModalBody>
    );
  }

  const selectedPrinterIndex = printers.findIndex(({ name }) => name === selectedPrinter);
  const preselectedIndex = selectedPrinterIndex === -1 ? undefined : selectedPrinterIndex;
  const getNameForUI = (name: string) => name.replace(/^[A-Z0-9]+-/, '');

  const onPrinterSelection = (id: string) => {
    setSelectedPrinter(id);
    setUserPrinterInLocalStorage(id);
  };

  return (
    <ModalBody>
      <Choice
        aria-label={t('otc-select-recovery-label-printer-sidebar.choiceAriaLabel')}
        preselectedIndex={preselectedIndex}
        size="large"
      >
        {printers.map(({ name }) => (
          <ChoiceItem
            id={name}
            key={name}
            onClick={onPrinterSelection}
            title={getNameForUI(name)}
          />
        ))}
      </Choice>
    </ModalBody>
  );
}
