import { useCancelReturnMutation } from '../../store/features/api/apiSlice';
import {
  useGetAppState,
} from '../../store/features/appStateSlice/appStateSlice';
import { useMarketLocale } from '../useMarketLocale';
import { CancelReturnPostMessage, IsPaid, WithHoldUntilPaid } from '../../models/SamsPostMessage';
import { CancelReturnParams, isHoldUntilPaidEnabled, isHoldUntilPaidEnabledAndPaid } from '../../store/features/api/cancelReturn/cancelReturn';

export const useCancelReturn = () => {
  const { countryCode } = useMarketLocale();
  const returnId = useGetAppState('returnId')!;
  const returnReference = useGetAppState('returnReference')!;
  const userId = useGetAppState('userId')!;
  const [cancelReturnMutation] = useCancelReturnMutation();

  const postMessageApi = window.chrome?.webview?.postMessage;
  const encodeData = (data:
  CancelReturnPostMessage<WithHoldUntilPaid, IsPaid>) => window.btoa(JSON.stringify(data));
  const failedPickupReason = useGetAppState('failedPickupReason');
  const postBody: CancelReturnParams['postBody'] = {
    updatedBy: userId,
    ...(failedPickupReason && { reasonCode: failedPickupReason }),
  };

  const cancelReturn = () => cancelReturnMutation(
    {
      countryCode, postBody, returnId,
    },
  )
    .unwrap()
    .then((data) => {
      if (!postMessageApi) return;
      const deliveryMethodId = data.returnOption?.deliveryMethodId;
      if (!deliveryMethodId) return;
      const basePostMessagePayload = {
        action: 'CANCEL' as const,
        error: '',
        message: '',
      };
      if (isHoldUntilPaidEnabled(data)) {
        const unpaidReturnOption = {
          deliveryMethodId: data.returnOption.deliveryMethodId,
          holdUntilPaid: true as true,
        };
        const holdUntilPaidUnpaid: CancelReturnPostMessage<true, false> = {
          ...basePostMessagePayload,
          returnData: {
            returnId,
            returnReference,
            returnOption: unpaidReturnOption,
          },
        };
        if (isHoldUntilPaidEnabledAndPaid(data)) {
          const {
            amount,
            currencyCode,
          } = data.returnOption.holdUntilPaid;
          const paidReturnOption = {
            ...unpaidReturnOption,
            amountPaid: amount,
            currencyCode,
          };
          const holdUntilPaidPaid: CancelReturnPostMessage<true, true> = {
            ...basePostMessagePayload,
            returnData: {
              returnId,
              returnReference,
              returnOption: paidReturnOption,
            },
          };
          postMessageApi(encodeData(holdUntilPaidPaid));
          return;
        }
        postMessageApi(encodeData(holdUntilPaidUnpaid));
        return;
      }
      postMessageApi(encodeData({
        ...basePostMessagePayload,
        returnData: {
          returnId,
          returnReference,
          returnOption: {
            deliveryMethodId,
            holdUntilPaid: false,
          },
        },
      }));
    })
    .catch((error: any) => {
      if (!postMessageApi) return;
      postMessageApi(encodeData({
        action: 'CANCEL',
        error: 'RETURN_CANCEL_FAILED',
        message: error.data?.message ?? 'Something went wrong, no additional information available',
        returnData: null,
      }));
    });
  return cancelReturn;
};
