import { useTranslation } from 'react-i18next';
import { SidebarHeader } from '../SidebarHeader';

export function OTCMoreMenuHeader() {
  const { t } = useTranslation();
  return (
    <SidebarHeader
      title={t('otc-more-menu-sidebar.title')}
    />
  );
}
