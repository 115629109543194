import { useEffect, useRef, useState } from 'react';

export type UseOnUnmountProps<T> = {
  initialValue: T;
  onUnmount: (currentValue: T) => void;
};

export function useOnUnmount<T = string>({
  initialValue,
  onUnmount,
}: UseOnUnmountProps<T>) {
  const [state, setState] = useState<T>(initialValue);
  const liveValueRef = useRef(initialValue);

  const setter = (currentValue: T) => {
    liveValueRef.current = currentValue;
    return setState(currentValue);
  };

  useEffect(() => () => {
    if (liveValueRef?.current !== initialValue) {
      onUnmount(liveValueRef.current);
    }
  }, []);

  return [state, setter] as const;
}
