import pencilIcon from '@ingka/ssr-icon/paths/pencil';
import { useTranslation } from 'react-i18next';
import { DataCard } from 'shared-frontend';
import Button from '@ingka/button';
import { CardTitle } from '../CardTitle/CardTitle';
import { useReturnMethodConfig } from './useReturnMethodConfig';
import { useGoToSidebarPage } from '../../store/features/sidebar/sidebarSlice';
import { CreateRouteOnly } from '../RouteOnly/RouteOnly';

function ReturnMethodCard(): React.ReactElement {
  const { t } = useTranslation();
  const openSidebar = useGoToSidebarPage('return-method');

  const {
    config,
    content,
    editDisabled,
    editLoading,
    selectedReturnMethod,
  } = useReturnMethodConfig();

  const ariaLabelledById = 'return-method-title-id';

  return (
    <DataCard
      ariaLabelledById={ariaLabelledById}
      className="text-sm"
      data-testid="return-method-card"
      cardButton={(
        <CreateRouteOnly>
          <Button
            disabled={editDisabled}
            loading={editLoading}
            ssrIcon={pencilIcon}
            iconPosition="trailing"
            aria-description={`${t('return-method-card.title')}`}
            text={t('commonly-reused.edit')}
            type={!selectedReturnMethod ? 'primary' : 'secondary'}
            onClick={openSidebar}
            size="small"
          />
        </CreateRouteOnly>
      )}
      content={content}
      config={config}
      title={(
        <CardTitle id={ariaLabelledById}>
          {t('return-method-card.title')}
        </CardTitle>
      )}
    />
  );
}

export default ReturnMethodCard;
