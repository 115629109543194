import { ModalBody } from '@ingka/modal';
import { ItemInfo, LineItemType, ProductAreaNo } from '@resolutions/item-info';
import { useGetArticleInSidebar } from '../../../store/features/OTCSlice/OTCSlice';
import { formatProductNumber } from '../../../utils/productNumber';
import { IssueGroup } from './IssueGroup';
import { QuantityGroup } from './QuantityGroup';
import { ConditionOfGoodsGroup } from './ConditionOfGoodsGroup';
import { OTCItemDetailsPriceGroup } from './OTCItemDetailsPriceGroup';
import { ItemNoteGroup } from './ItemNoteGroup';

export function OTCItemDetailsBody() {
  const item = useGetArticleInSidebar();

  if (!item) {
    return <ModalBody />;
  }

  return (
    <ModalBody>
      <div className="flex flex-col gap-7 items-start">
        <ItemInfo
          altText={item.productImage?.alt ?? ''}
          itemDescription={item.description}
          itemDimensions={item.dimensions ?? undefined}
          itemName={item.name}
          itemNo={formatProductNumber(item.itemNo)}
          lineItemType={item.itemType as LineItemType}
          productAreaNo={item.productAreaCode as ProductAreaNo}
          productImageUrl={item.productImage?.url}
          quantity={item.quantity}
          showQuantityOnImage
        />
        <hr className={`[border-block-start-width:1px]
        [border-block-start-style:solid] [border-block-start-color:initial] w-full`}
        />
        <QuantityGroup item={item} />
        <IssueGroup item={item} />
        <ItemNoteGroup item={item} />
        <ConditionOfGoodsGroup item={item} />
        <OTCItemDetailsPriceGroup item={item} />
      </div>
    </ModalBody>
  );
}
