import { reportErrorToSentry } from './utils/reportErrorToSentry';

export type GetUserResponse = {
  accessToken: string | null;
  account: {
    username: string | null;
  };
  expiresOn: Date | null;
  extExpiresOn: Date | null;
  status: GetUserStatus;
};
export type GetUserStatus = 'succeeded' | 'failed';

async function getAuth(): Promise<GetUserResponse> {
  try {
    const authentication = window.salja?.authentication;
    if (!authentication) {
      throw new Error('Salja authentication not available');
    }
    const { getUser } = authentication;
    const user = await getUser();
    const { accessToken, expiresOn, extExpiresOn } = user;
    const status: GetUserStatus = accessToken
    && typeof expiresOn === 'object'
    && typeof extExpiresOn === 'object'
      ? 'succeeded' : 'failed';
    return { ...user, status };
  } catch (error) {
    reportErrorToSentry(error);
    return {
      accessToken: null,
      account: { username: null },
      expiresOn: null,
      extExpiresOn: null,
      status: 'failed',
    };
  }
}

export default getAuth;
