import { useGetPrintersQuery } from '../store/features/api/apiSlice';

export const useGetPrinters = () => {
  const isProd = !!window.location.host.match(/^(prod\.)?coworker\.ingka\.com$/);

  return useGetPrintersQuery({
    buCode: isProd ? '445' : 'ITF',
    countryCode: 'SE',
  });
};
