import { Questionnaire, IsomReturnOption } from 'shared-frontend';
import { useQuestionnaireRequest } from '../../../../components/app/initialFetchUtils';

export const useQuestionnaireForReturnMethod = (
  returnOption: IsomReturnOption | undefined,
): Questionnaire[] | null => {
  const { data: Qresp } = useQuestionnaireRequest();

  const hasQuestionnaire = !!returnOption && !!Qresp?.questionnaire
    && !!Qresp.questionnaire[returnOption]?.length;

  return hasQuestionnaire ? Qresp.questionnaire[returnOption] : null;
};
