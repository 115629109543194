import Text from '@ingka/text';

export function TextLabel({
  text,
  className = '',
}: { text: string, className?: string }) {
  return (
    <Text
      bodySize="m"
      tagName="h3"
      className={`flex justify-between text-dark font-bold ${className}`}
    >
      {text}
    </Text>
  );
}
