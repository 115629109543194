import Button from '@ingka/button';

type UnderlineTextButtonProps = {
  text: React.ReactNode,
  onClick: () => void,
  ariaLabel?: string
  testid?: string
};

export function UnderlineTextButton(
  {
    text, onClick, ariaLabel, testid = 'underlineTextButton',
  }: UnderlineTextButtonProps,
) {
  return (
    <Button
      data-testid={testid}
      onClick={onClick}
      size="xsmall"
      text={text}
      type="plain"
      className="bg-none border-none outline-[inherit] p-0 hover:underline font-normal text-left"
      aria-label={ariaLabel}
    />
  );
}
