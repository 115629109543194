import { useTranslation } from 'react-i18next';
import { SidebarHeader } from '../SidebarHeader';
import { useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';
import { useGetOTCState } from '../../../store/features/OTCSlice/OTCSlice';
import { useShowOTCArticleSidebar } from '../../../store/features/standAloneActions/actions';

export function OTCConditionOfGoodsHeader() {
  const { t } = useTranslation();
  const sidebarArticleIdentifier = useGetOTCState('sidebarArticleIdentifier');
  const showOTCArticleSidebar = useShowOTCArticleSidebar();
  const showBulkIssuePickerSidebar = useGoToSidebarPage('otc-issue-picker');

  const backToSelectIssue = () => {
    if (sidebarArticleIdentifier) {
      showOTCArticleSidebar(sidebarArticleIdentifier, 'otc-issue-picker');
    } else {
      showBulkIssuePickerSidebar();
    }
  };

  return (
    <SidebarHeader
      ariaBackBtnTxt={t('questionnaire-sidebar.backBtn')}
      backBtnClick={backToSelectIssue}
      title={t('otc-condition-of-goods-sidebar.title')}
    />
  );
}
