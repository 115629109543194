import { useTranslation } from 'react-i18next';
import { formatTimeRange, getDateLocale } from 'shared-frontend';
import { isOldExchangeResolution } from '../../models/Sac';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { isViewReturn } from '../../store/features/appStateSlice/utils';
import { useMarketLocale } from '../../hooks/useMarketLocale';

type FormatUIStrings = {
  deliveryFromDateTime: string | null;
  deliveryToDateTime: string | null;
  tspId: string | null;
  tspName: string | null;
};
export const useFormatTspAndTimeWindowForUI = () => {
  const { languageCode, countryCode } = useMarketLocale();
  const { t } = useTranslation();
  const locale = getDateLocale(languageCode, countryCode);

  const formatUIStrings = ({
    deliveryFromDateTime, deliveryToDateTime, tspId, tspName,
  }: FormatUIStrings) => {
    let deliveryDateTimeStr: string | null = null;
    if (deliveryFromDateTime && deliveryToDateTime) {
      try {
        deliveryDateTimeStr = formatTimeRange({
          endDate: new Date(deliveryToDateTime),
          locale,
          startDate: new Date(deliveryFromDateTime),
        });
      } catch (e: unknown) {
        const errorMessage = `formatTimeRange failed with deliveryToDateTime="${deliveryToDateTime}" and deliveryFromDateTime="${deliveryFromDateTime}"`;
        const error = new Error(errorMessage, { cause: e });
        error.name = 'ExchangeResolution component failed to render';
        throw error;
      }
    }

    const tspStr = (tspId && tspName) ? t('exchangeResolution.tsp', { tspName, tspId }) : null;
    return { deliveryDateTimeStr, tspStr };
  };

  return formatUIStrings;
};

export const useGetExchangeResolutionData = () => {
  const customerReturn = useGetAppState('customerReturn');
  if (!customerReturn) return null;

  if (isViewReturn(customerReturn)) {
    const { outboundOrderReference } = customerReturn.returnAgreement.returnSettlement;

    if (!outboundOrderReference) return null;

    const { orderNumber, tspData, timeWindow } = outboundOrderReference;
    return {
      deliveryFromDateTime: timeWindow?.fromDateTime ?? null,
      deliveryToDateTime: timeWindow?.toDateTime ?? null,
      orderNumber,
      tspId: tspData?.id ?? null,
      tspName: tspData?.name ?? null,
    };
  }

  const { exchangeResolution } = customerReturn;
  if (!exchangeResolution) return null;

  const { orderNumber } = exchangeResolution;

  if (isOldExchangeResolution(exchangeResolution)) {
    const { deliveryFromDateTime, deliveryToDateTime } = exchangeResolution;
    return {
      deliveryFromDateTime: deliveryFromDateTime ?? null,
      deliveryToDateTime: deliveryToDateTime ?? null,
      orderNumber,
      tspId: null,
      tspName: null,
    };
  }

  const { timeWindow, tspData } = exchangeResolution;
  return {
    deliveryFromDateTime: timeWindow?.fromDateTime ?? null,
    deliveryToDateTime: timeWindow?.toDateTime ?? null,
    orderNumber,
    tspId: tspData?.id ?? null,
    tspName: tspData?.name ?? null,
  };
};
