import ListView from '@ingka/list-view';
import { ModalBody } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../ListItem';
import { useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';

export function OTCMoreMenuBody() {
  const { t } = useTranslation();
  const goToSelectRecoveryLabelPrinter = useGoToSidebarPage('otc-select-recovery-label-printer');

  return (
    <ModalBody>
      <ListView id="otc-show-more-list-view" size="large">
        <ListItem
          onClick={goToSelectRecoveryLabelPrinter}
          title={t('otc-more-menu-sidebar.listItemSelectRecoveryLabelPrinter')}
        />
      </ListView>
    </ModalBody>
  );
}
