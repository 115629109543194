import QuantityStepper from '@ingka/quantity-stepper';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { PopItem } from '../../store/features/OTCSlice/reducerUtils';
import { useChangeItemsQuantityForArticle } from '../../store/features/OTCSlice/OTCSlice';

export function OTCArticleQuantity({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const inputChange = useChangeItemsQuantityForArticle(item);
  const [keyboardInput, setKeyboardInput] = useState<null | string>(null);

  const parseInput = (input: string | null): number => {
    if (input === '') {
      // The QuantityStepper's "value" prop is typed
      // as option key with a number value, however, we need to pass an empty string
      //  when the input is empty
      return '' as unknown as number;
    }

    if (input === null) {
      return item.quantity;
    }

    return Number(input);
  };

  return (
    <QuantityStepper
      ariaDescribedBy={
        t('otc-article-table.quantity-stepper.ariaDescription', { ariaProductName: item.name })
      }
      ariaDescribedById={`quantity-stepper-helper-${item.itemLineId}`}
      ariaLabelIncrease={t('otc-article-table.quantity-stepper.ariaIncrease')}
      ariaLabelDecrease={t('otc-article-table.quantity-stepper.ariaDecrease')}
      ariaLabelInput={t('otc-article-table.quantity-stepper.ariaInput')}
      className="
        [&.quantity-stepper__input:disabled]:text-lightest
        [&.quantity-stepper__input]:p-0
        [&_.quantity-stepper\_\_increase]:my-[2px]
      "
      data-testid="quantity-stepper"
      fluid={false}
      maxVal={item.maxQuantity}
      minVal={keyboardInput === '' ? 0 : 1}
      small
      value={parseInput(keyboardInput)}
      onDecrease={() => { inputChange(item.quantity - 1); }}
      onIncrease={() => { inputChange(item.quantity + 1); }}
      onBlur={() => {
        if (keyboardInput !== null) {
          inputChange(keyboardInput);
          setKeyboardInput(null);
        }
      }}
      onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyboardInput(e.target.value);
      }}
    />
  );
}
