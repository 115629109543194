import { useTranslation } from 'react-i18next';
import InputField from '@ingka/input-field';
import FormField from '@ingka/form-field';
import TextArea from '@ingka/text-area';
import Text from '@ingka/text';
import { useState } from 'react';
import { TextLabel } from './TextLabel';
import { useFormatItemPrice } from '../../../hooks/OTCItemPrice/useFormatItemPrice';
import { hasChangedPrice, PopItem } from '../../../store/features/OTCSlice/reducerUtils';
import { useSetArticlePrice } from '../../../store/features/OTCSlice/OTCSlice';
import { getUnitPrice as getUnitPriceToRender } from '../../../utils/getUnitPrice';
import { useOnUnmount } from '../../../hooks/useOnUnmount/useOnUnmount';
import { useGetSidebarState } from '../../../store/features/sidebar/sidebarSlice';

export function OTCItemDetailsPriceGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const [formIsDirty, setFormIsDirty] = useState(false);
  const dispatchChangePrice = useSetArticlePrice();
  const actionSource = useGetSidebarState('sidebarActionSource');

  const {
    unitPrice,
    initialUnitPrice,
  } = getUnitPriceToRender(item.price);
  const priceChangeMessage = item.price.unit.priceChange?.message;
  const [livePriceAndMessage, setPriceAndMessage] = useOnUnmount({
    initialValue: { priceToRender: unitPrice, priceChangeMessage },
    onUnmount: (liveValue) => {
      dispatchChangePrice({
        price: liveValue.priceToRender,
        message: liveValue.priceChangeMessage,
        itemRef: {
          popId: item.popId ?? '',
          itemLineId: item.itemLineId,
        },
      });
    },
  });
  const {
    priceToRender: livePrice,
    priceChangeMessage: livePriceChangeMessage,
  } = livePriceAndMessage;

  const { currencyCode, quantity } = item;
  const { formattedPriceUnit: initialUnitPriceFormatted } = useFormatItemPrice({
    currencyCode,
    quantity,
    price: Number(initialUnitPrice),
  });
  const {
    formattedPriceTotal: liveTotalPriceFormatted,
    currencySymbol,
    priceUnit: liveUnitPriceFormatted,
  } = useFormatItemPrice({
    currencyCode,
    quantity,
    price: Number(livePrice),
  });

  const formIsValid = livePrice !== initialUnitPriceFormatted && !!livePriceChangeMessage;

  const validateInput = (input: string) => (
    /^$|^[0-9]+([.,][0-9]*)?$/.test(input)
  );

  if (initialUnitPrice === 'N/A') return <div>Price Change not Available</div>;

  const totalPriceLabel = t('otc-item-details-sidebar.price-group.amount', { amount: liveTotalPriceFormatted });

  const renderPrice = () => (formIsDirty ? livePrice : liveUnitPriceFormatted);

  return (
    <div className="w-full space-y-3">
      <TextLabel text={t('otc-item-details-sidebar.price-group.title')} />
      <InputField
        data-testid="otc-item-details-price-input"
        id="otc-item-details-price"
        label={t('otc-item-details-sidebar.price-group.originalPrice', { originalPrice: initialUnitPriceFormatted })}
        onChange={(e) => {
          setFormIsDirty(true);
          const { value } = e.target;

          if (!validateInput(value)) return;
          setPriceAndMessage({
            priceToRender: value,
            priceChangeMessage: livePriceChangeMessage,
          });
        }}
        prefixLabel={currencySymbol}
        type="number"
        value={renderPrice()}
        data-autofocus={(actionSource === 'price') ? true : undefined}
      />

      <Text
        bodySize="m"
        tagName="h3"
      >
        {totalPriceLabel}
      </Text>
      <FormField
        fieldHelper={{
          msg: t('otc-item-details-sidebar.price-group.priceChangeReason'),
          id: 'otc-item-details-price-comment',
        }}
        shouldValidate={hasChangedPrice(
          livePrice,
          item?.price?.unit.paidPrice,
        )}
        valid={formIsValid}
        validation={{
          msg: t('otc-item-details-sidebar.price-group.priceAdjustmentMessageRequired'),
          id: 'no-message-on-price-change',
        }}
      >
        <TextArea
          data-testid="otc-item-details-price-comment"
          id="otc-item-details-price-comment"
          label={t('otc-item-details-sidebar.price-group.priceComment')}
          req
          onChange={(e) => {
            setPriceAndMessage({
              priceToRender: livePrice,
              priceChangeMessage: e.target.value,
            });
          }}
          value={livePriceChangeMessage}
          disabled={!hasChangedPrice(
            livePrice,
            item?.price?.unit.paidPrice,
          )}
          describedById="no-message-on-price-change"
        />
      </FormField>
    </div>
  );
}
