import { ModalBody } from '@ingka/modal';
import ListView from '@ingka/list-view';
import { useTranslation } from 'react-i18next';
import { useGetOTCState, useGetCheckedItemsForPop } from '../../../store/features/OTCSlice/OTCSlice';
import { useGoToPromptPage } from '../../../store/features/prompt/promptSlice';
import { useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';
import { ListItem } from '../ListItem';

export function OTCPoPActionsBody() {
  const { t } = useTranslation();
  const currentPopId = useGetOTCState('actionsSidebarPopId');
  const goToConditionOfGoodsSidebar = useGoToSidebarPage('otc-condition-of-goods');
  const goToIssuePickerSidebar = useGoToSidebarPage('otc-issue-picker');
  const goToPromptRemovePop = useGoToPromptPage('otc-remove-pop');
  const goToPromptRemoveSelectedArticles = useGoToPromptPage('otc-remove-selected-articles-pop');
  const items = useGetCheckedItemsForPop(currentPopId);

  const hasSelectedArticles = items?.some((item) => item.checked);
  const isSetConditionOfGoodsDisabled = items?.every(
    (item) => !item.checked || item.itemType === 'SGR' || !item.issue,
  );
  const isRemoveSelectedArticlesDisabled = !items?.length;

  return (
    <ModalBody>
      <ListView
        id="popActions"
        showDivider
      >
        <ListItem
          key="REMOVE_POP"
          onClick={goToPromptRemovePop}
          title={t('prompt-actions.confirm-remove-proof-of-purchase.title')}
        />
      </ListView>
      <ListView
        id="selectedArticlesActions"
        showDivider={false}
      >
        <ListItem
          data-testid="remove-selected-articles"
          disabled={isRemoveSelectedArticlesDisabled}
          key="REMOVE_SELECTED_ARTICLES"
          onClick={goToPromptRemoveSelectedArticles}
          title={t('prompt-actions.confirm-remove-selected-articles.title')}
        />
        <ListItem
          data-testid="bulk-select-issue"
          disabled={!hasSelectedArticles}
          key="BULK_SELECT_ISSUE"
          onClick={goToIssuePickerSidebar}
          title={t('otc-table-actions.bulkSelectIssue')}
        />
        <ListItem
          data-testid="bulk-select-condition-of-goods"
          disabled={isSetConditionOfGoodsDisabled}
          key="BULK_SELECT_CONDITION_GOODS"
          onClick={goToConditionOfGoodsSidebar}
          title={t('otc-table-actions.bulkSelectConditionOfGoods')}
        />
      </ListView>
    </ModalBody>
  );
}
