import FormField, { FormFieldContext } from '@ingka/form-field';
import { ModalBody } from '@ingka/modal';
import TextArea from '@ingka/text-area';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddReturnComment, useGetOTCState } from '../../../store/features/OTCSlice/OTCSlice';
import { useOnUnmount } from '../../../hooks/useOnUnmount/useOnUnmount';

const MAX_COMMENT_LENGTH = 2000;

function TextAreaReturnComment() {
  const { setCharacterCount } = useContext(FormFieldContext);
  const commentState = useGetOTCState('returnComment');
  const addReturnComment = useAddReturnComment();

  const { t } = useTranslation();
  const [comment, setComment] = useOnUnmount({
    initialValue: commentState?.comment || '',
    onUnmount: (commentString) => {
      const trimmedComment = commentString.slice(0, MAX_COMMENT_LENGTH);
      addReturnComment(trimmedComment);
    },
  });

  useEffect(() => {
    const characterLength = comment.length;
    setCharacterCount?.(characterLength);
  }, [comment, setCharacterCount]);

  return (
    <TextArea
      data-testid="otc-item-return-comment"
      id="otc-return-comment"
      label={t('otc-add-return-comment-sidebar.inputLabel')}
      defaultValue={comment}
      onChange={(e) => {
        setComment(e.target.value);
      }}
    />
  );
}

export function OTCAddReturnCommentBody() {
  return (
    <ModalBody>
      <FormField characterLimit={MAX_COMMENT_LENGTH}>
        <TextAreaReturnComment />
      </FormField>
    </ModalBody>
  );
}
