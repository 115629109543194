import { createAction } from '@reduxjs/toolkit';
import { CustomerReturn } from '../../../models';
import { SidebarArticleIdentifier } from '../OTCSlice/reducerUtils';

export const sidebarKeys = [
  'history-log',
  'more-menu',
  'otc-add-return-comment',
  'otc-article-actions',
  'otc-article-viewer',
  'otc-blind-return-actions',
  'otc-blind-return-article-actions',
  'otc-condition-of-goods',
  'otc-issue-picker',
  'otc-item-details',
  'otc-more-menu',
  'otc-pop-actions',
  'otc-search',
  'otc-select-recovery-label-printer',
  'questionnaire',
  'return-method',
  'time-window-calendar',
] as const;

export type SidebarPageKey = typeof sidebarKeys[number];

export const timeWindowSet = createAction<{
  hasCommittedMethod: boolean
  timeWindowHasChangedInViewMode: boolean
}>('timeWindowSet');

export type ArticlesWithIncorrectUnit = {
  name?: string;
  productNumber?: string;
}[] | null;
export const articlesWithIncorrectUnit = createAction<ArticlesWithIncorrectUnit>(
  'articlesWithIncorrectUnit',
);

export type InitialCreateModePayload = {
  accessToken: string;
  customerReturn: CustomerReturn | null;
  isAuthenticated: boolean;
  userId: string | null;
};
export const setInitialCreateMode = createAction<InitialCreateModePayload>(
  'setInitialCreateMode',
);
export const removePoP = createAction('removePoP');

export type EditItemSource = 'price' | 'comment';
export const showOTCArticleSidebar = createAction<{
  actionSource?: EditItemSource,
  sidebarArticleIdentifier: SidebarArticleIdentifier,
  sidebarPage: SidebarPageKey,
}>('showOTCArticleSidebar');

export const removeArticle = createAction('remove-article');
export const removeArticlesPop = createAction('remove-article-pop');
export const removeBlindReturnArticle = createAction('remove-blind-return-article');
export const closeSidebar = createAction('close-sidebar');
