import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SuccessfulScan } from '@resolutions/scanner-contract';
import { ItemAdded, OpenStatus } from '@resolutions/item-picker-contract';
import { SelectionState } from '@resolutions/condition-of-goods';
import { useLamnaSelector, useLamnaDispatch } from '../../app/hooks';
import {
  PopId,
} from '../../../hooks/useResolutionsScanner/validators/proofOfPurchase';
import {
  setActionsSidebarPop,
} from '../standAloneActions/actions';
import {
  EditedFields,
  filterBlindReturnItemsByItemNo,
  getItemIdentifier,
  getArticleInSidebar,
  isPoPItem,
  IssueType,
  ItemRef,
  OTCItemValidationProps,
  OTCState,
  PopItem,
  setArticleIssue,
  setArticleComment,
  setArticlePrice,
  setConditionOfGoods,
  SetPriceAndMessage,
  StatePop,
  updateItemsForPop,
  updateQuantityForPopItem,
  UseOTCItemValidationReturnValue,
} from './reducerUtils';
import {
  closeSidebar,
  removeArticle,
  removeBlindReturnArticle,
  removePoP,
  removeArticlesPop,
  showOTCArticleSidebar,
} from '../standAloneActions/extraReducersActions';
import { addApiMatchers } from './apiMatchers';

export type PopSuccessfulScan = SuccessfulScan<PopId>;

export const OTCInitialState: OTCState = {
  itemValidation: {},
  actionsSidebarPopId: null,
  blindReturn: { checked: false, items: [] },
  pops: [],
  pipState: {
    isOpen: false,
    purchaseId: '',
  },
  returnComment: null,
  selectedPrinter: null,
  sidebarArticleIdentifier: null,
};

export const OTCSlice = createSlice({
  name: 'OTC',
  initialState: OTCInitialState,
  reducers: {
    updateItemsForPop,
    updateQuantityForPopItem,
    addReturnComment: (state, action: PayloadAction<string>) => {
      const comment = {
        comment: action.payload,
        date: new Date().toISOString(),
      };
      state.returnComment = comment;
    },
    toggleBlindReturnAllChecked: (state) => {
      state.blindReturn = {
        checked: !state.blindReturn.checked,
        items: state.blindReturn.items.map((item) => ({
          ...item,
          checked: !state.blindReturn.checked,
        })),
      };
    },
    toggleBlindReturnItemChecked: (state, action: PayloadAction<{ itemLineId: string }>) => {
      state.blindReturn.items = state.blindReturn.items.map((item) => {
        if (item.itemLineId !== action.payload.itemLineId) return item;

        return {
          ...item,
          checked: !(item as PopItem).checked,
        };
      });
    },
    changeBlindReturnItemQuantity: (
      state,
      action: PayloadAction<{ itemNo: string, quantity?: number, }>,
    ) => {
      state.blindReturn.items = state.blindReturn.items.map((item) => {
        if (isPoPItem(item)) {
          if (item.itemNo !== action.payload.itemNo) return item;
          let quantity;
          const usingScan = action.payload.quantity === undefined;
          if (usingScan) {
            quantity = item.quantity + 1;
          } else {
            quantity = action.payload.quantity;
          }
          const restrictedQuantity = Number(quantity) > item.maxQuantity
            ? item.maxQuantity : quantity || 1;
          const popItemEntry = {
            ...item,
            quantity: restrictedQuantity,
          };
          return popItemEntry;
        }
        return item;
      });
    },
    setArticleIssue,
    setArticleComment,
    setPipState: (state, action: PayloadAction<OpenStatus>) => {
      const { openStatus, purchaseId } = action.payload;
      state.pipState = {
        isOpen: openStatus === 'open',
        purchaseId,
      };
    },
    togglePopChecked: (
      state: OTCState,
      action: PayloadAction<{ popId: string }>,
    ) => {
      const { popId } = action.payload;
      state.pops = state.pops.map((pop) => {
        if (pop.id !== popId) return pop;
        return {
          ...pop,
          items: pop.items.map((item) => ({
            ...item,
            checked: !pop.checked,
          })),
          checked: !pop.checked,
        };
      });
    },
    togglePopItemsChecked: (
      state: OTCState,
      action: PayloadAction<{ popId: string, itemLineId: string }>,
    ) => {
      const { popId, itemLineId } = action.payload;
      state.pops = state.pops.map((pop) => {
        if (pop.id !== popId) return pop;
        const { items } = pop;
        const foundItem = items.find((item) => item.itemLineId === itemLineId);
        if (!foundItem) return pop;
        return {
          ...pop,
          items: items.map((item) => {
            if (item.itemLineId !== itemLineId) return item;
            return {
              ...item,
              checked: !item.checked,
            };
          }),
        };
      });
    },
    setConditionOfGoods,
    setArticlePrice,
    setSelectedPrinter: (state, action: PayloadAction<string>) => {
      state.selectedPrinter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        showOTCArticleSidebar,
        (state, action) => {
          state.sidebarArticleIdentifier = action.payload.sidebarArticleIdentifier;
        },
      )
      .addCase(
        setActionsSidebarPop,
        (state: OTCState, action: PayloadAction<{ popId: string | null }>) => {
          state.actionsSidebarPopId = action.payload.popId;
        },
      )
      .addCase(
        closeSidebar,
        (state: OTCState) => {
          state.actionsSidebarPopId = null;
          state.sidebarArticleIdentifier = null;
        },
      )
      .addCase(removePoP, (state: OTCState) => {
        const popId = state.actionsSidebarPopId;
        state.pops = state.pops.filter((pop) => pop.id !== popId);
        state.actionsSidebarPopId = null;
      })
      .addCase(removeArticle, (state) => {
        const articleToRemove = getArticleInSidebar(state);
        delete state.itemValidation[`${articleToRemove?.popId}-${articleToRemove?.itemLineId}`];
        state.pops = state.pops.map((pop) => {
          if (pop.id !== articleToRemove?.popId) return pop;

          const updatedPop = { ...pop };
          updatedPop.items = pop.items.filter(
            (item) => item.itemLineId !== articleToRemove?.itemLineId,
          );
          return updatedPop;
        });
      })
      .addCase(removeBlindReturnArticle, (state) => {
        const articleToRemove = getArticleInSidebar(state);
        filterBlindReturnItemsByItemNo(state, articleToRemove?.itemNo);
      })
      .addCase(removeArticlesPop, (state: OTCState) => {
        const popId = state.actionsSidebarPopId;

        const getIdentifiersToRemove = (pop: StatePop) => {
          if (pop.id === popId) {
            return pop.items
              .filter((item) => item.checked)
              .map((item) => `${popId}-${item.itemLineId}`);
          }
          return [];
        };
        const filteredItems = (pop: StatePop) => {
          if (pop.id === popId) {
            const uncheckedItems = pop.items.filter((item) => item.checked === false);
            return {
              ...pop,
              items: uncheckedItems,
              checked: false,
            };
          }
          return pop;
        };
        const itemsToRemoveFromValidation = state.pops.map(getIdentifiersToRemove);
        itemsToRemoveFromValidation.flat().forEach((id) => {
          if (id) {
            delete state.itemValidation[id];
          }
        });
        state.pops = state.pops.map(filteredItems);
        state.actionsSidebarPopId = null;
      });
    addApiMatchers(builder);
  },
});
// convenience selectors
export const useGetOTCState = <Key extends keyof OTCState>(key: Key) => useLamnaSelector(
  (state) => state.otc[key],
);

export const useGetPop = (purchaseId: string) => useGetOTCState('pops').find((pop) => pop.id === purchaseId);

export const useGetAllItemsFromPops = () => {
  const pops = useGetOTCState('pops');
  return pops.flatMap((pop) => pop.items);
};

export const useGetItemsForPop = (popId: string | null = '') => {
  const matchingPop = useGetOTCState('pops').find((pop) => pop.id === popId);
  return matchingPop?.items;
};

export const useGetCheckedItemsForPop = (popId: string | null = '') => {
  const matchingPopItems = useGetItemsForPop(popId) ?? [];
  return matchingPopItems.filter((item) => item.checked);
};

export const useGetArticleInSidebar = () => {
  const otcState = useLamnaSelector((state) => state.otc);
  return getArticleInSidebar(otcState);
};

type BlindReturnItem = PopItem;
type UseGetLineIdItemProps = {
  lineId: string;
  popId: string | null;
};
export const useGetLineIdItem = ({
  lineId,
  popId,
}: UseGetLineIdItemProps): BlindReturnItem | PopItem | undefined => {
  if (!popId) {
    const blindReturnItem = useGetOTCState('blindReturn').items.find((item) => item.itemLineId === lineId);
    return blindReturnItem as BlindReturnItem;
  }
  const popItems = useGetItemsForPop(popId);
  return popItems?.find((item) => item.itemLineId === lineId);
};

export const useGetItemValidation = (identifier: UseOTCItemValidationReturnValue['itemIdentifier']) => {
  const itemValidation = useGetOTCState('itemValidation');
  return itemValidation[identifier];
};

// convenience dispatchers
export const useAddReturnComment = () => {
  const dispatch = useLamnaDispatch();
  return (comment: string) => {
    dispatch(OTCSlice.actions.addReturnComment(comment));
  };
};
export const useTogglePopChecked = () => {
  const dispatch = useLamnaDispatch();
  return (popId: string) => {
    dispatch(OTCSlice.actions.togglePopChecked({ popId }));
  };
};

export const useTogglePopItemsChecked = () => {
  const dispatch = useLamnaDispatch();
  return (popId: string, itemLineId: string) => {
    dispatch(OTCSlice.actions.togglePopItemsChecked({ popId, itemLineId }));
  };
};
export const useUpdateItemsForPop = () => {
  const dispatch = useLamnaDispatch();
  return (itemAdded: ItemAdded) => {
    dispatch(OTCSlice.actions.updateItemsForPop(itemAdded));
  };
};

export const useToggleBlindReturnAllChecked = () => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(OTCSlice.actions.toggleBlindReturnAllChecked());
  };
};

export const useToggleBlindReturnItemChecked = () => {
  const dispatch = useLamnaDispatch();
  return (itemLineId: string) => {
    dispatch(OTCSlice.actions.toggleBlindReturnItemChecked({ itemLineId }));
  };
};

export const useChangeBlindReturnItemQuantity = () => {
  const dispatch = useLamnaDispatch();
  return (itemNo: string, quantity?: number | string) => {
    dispatch(OTCSlice.actions.changeBlindReturnItemQuantity({
      itemNo,
      quantity: typeof quantity === 'string' ? Number(quantity) : quantity,
    }));
  };
};

export const useChangeItemsQuantityForArticle = (popItem: PopItem) => {
  const dispatch = useLamnaDispatch();
  const changeBlindQuantity = useChangeBlindReturnItemQuantity();
  const isBlindReturn = !popItem.popId;

  if (isBlindReturn) {
    return (quantity: number | string) => changeBlindQuantity(popItem.itemNo, quantity);
  }

  return (quantity: number | string) => {
    dispatch(OTCSlice.actions.updateQuantityForPopItem(
      { purchaseId: popItem.popId!, item: { ...popItem, quantity: Number(quantity) } },
    ));
  };
};

export const useSetOpenPip = () => {
  const dispatch = useLamnaDispatch();
  return (pipState: OpenStatus) => {
    dispatch(OTCSlice.actions.setPipState(pipState));
  };
};

export const useSetConditionOfGoods = () => {
  const dispatch = useLamnaDispatch();
  return (selection: SelectionState) => {
    dispatch(OTCSlice.actions.setConditionOfGoods(selection));
  };
};

export const useSetArticleIssue = () => {
  const dispatch = useLamnaDispatch();
  return (issue: IssueType) => {
    dispatch(OTCSlice.actions.setArticleIssue({ issue }));
  };
};

export const useSetArticleComment = () => {
  const dispatch = useLamnaDispatch();
  return (comment: string, itemRef: ItemRef) => {
    dispatch(OTCSlice.actions.setArticleComment({ comment, itemRef }));
  };
};
export const useSetArticlePrice = () => {
  const dispatch = useLamnaDispatch();

  function changePrice(
    {
      price,
      message,
      itemRef,
    }: SetPriceAndMessage,
  ): void {
    dispatch(OTCSlice.actions.setArticlePrice({ price, message, itemRef }));
  }
  return changePrice;
};

export const useGetAllItemsValidation = () => {
  const itemValidation = useGetOTCState('itemValidation');
  const validatedItems = Object.keys(itemValidation ?? {});

  const getItemFields = (item: string) => Object.keys(itemValidation[item] ?? {});

  const isFieldInvalid = (item: string, field: string) => (
    itemValidation[item] as Record<string, any>
  )[field].isValid === false;

  const hasInvalidFields = (item: string) => {
    const fields = getItemFields(item);
    return fields.some((field) => isFieldInvalid(item, field));
  };

  const itemsWithInvalidFields = validatedItems.filter((item) => hasInvalidFields(item));

  return itemsWithInvalidFields;
};

export function useOTCItemValidation({ lineId, popId }: OTCItemValidationProps) {
  const itemIdentifier = getItemIdentifier(lineId, popId);
  const storedValidation = useGetItemValidation(itemIdentifier);
  const validatedFields: Array<EditedFields> = Object.keys(
    storedValidation ?? {},
  ) as Array<EditedFields>;
  const getValidation = () => {
    let isValid = true;
    validatedFields.forEach((validation) => {
      if (storedValidation?.[validation]?.isValid === false) {
        isValid = false;
      }
    });
    return isValid;
  };
  const isValid = validatedFields.length > 0 ? getValidation() : null;

  return {
    itemValidation: storedValidation,
    isValid,
    validatedFields,
    hasValidatedFields: validatedFields.length > 0,
  };
}

export const useSetSelectedPrinter = () => {
  const dispatch = useLamnaDispatch();
  return (comment: string) => {
    dispatch(OTCSlice.actions.setSelectedPrinter(comment));
  };
};

export const useHasBlindReturnOrPop = () => {
  const blindReturn = useGetOTCState('blindReturn');
  const pops = useGetOTCState('pops');
  return !!blindReturn.items.length || !!pops.length;
};
