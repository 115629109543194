import { useTranslation } from 'react-i18next';
import { PopItem } from '../../../store/features/OTCSlice/reducerUtils';
import { TextLabel } from './TextLabel';
import { ArticleIssue } from '../../ArticleIssue/ArticleIssue';

export function IssueGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();

  return (
    <div className="w-full space-y-3">
      <TextLabel text={t('otc-item-details-sidebar.issue-group.title')} />
      <ArticleIssue item={item} />
    </div>
  );
}
