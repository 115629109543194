import Text from '@ingka/text';

interface ArticleInputProps {
  ariaLabel: string;
  className?: string;
  value: string;
}

function ArticleInput({ ariaLabel, className, value }: ArticleInputProps) {
  return (
    <Text
      tagName="span"
      className={`p-2 rounded-sm border-[1px] border-lightest bg-white grow font-normal text-base h-9 leading-7 w-full overflow-hidden whitespace-nowrap text-ellipsis relative xl:top-0 text-m ${className}`}
      aria-label={ariaLabel}
      title={value}
    >
      {value}
    </Text>
  );
}

export default ArticleInput;
