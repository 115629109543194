import {
  useRefundCalcQuery,
} from '../store/features/api/apiSlice';
import { useLamnaSelector } from '../store/app/hooks';
import { useInitialRequestData } from '../components/app/initialFetchUtils';
import { useViewRoute } from './useAppRoutes';
import { CustomerReturn, CustomerReturnItem } from '../models';
import { useGetAppState } from '../store/features/appStateSlice/appStateSlice';

export const useRefundCalcResponse = () => {
  const isViewMode = useViewRoute();
  const { items, countryCode } = useInitialRequestData();
  const selectedReturnMethod = useLamnaSelector(
    (state) => state.selectedReturnMethod.selectedReturnMethod,
  );
  const { paidBy, isCreditPayment } = useGetAppState('customerReturn') as CustomerReturn;
  const servicePrice = selectedReturnMethod?.price;

  return useRefundCalcQuery(
    {
      items: items as CustomerReturnItem[],
      servicePrice: servicePrice!,
      countryCode: countryCode!,
      selectedReturnOptionId: selectedReturnMethod?.id!,
      paidBy,
      isCreditPayment,
    },
    {
      skip: items.length === 0
        || servicePrice === undefined
        || countryCode === null
        || paidBy === undefined
        || selectedReturnMethod?.id === undefined
        || isViewMode,
    },
  );
};
