import { useEffect } from 'react';
import { useGetPrinters } from '../useGetPrinters';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { useGoToSidebarPage } from '../../store/features/sidebar/sidebarSlice';
import { useSetSelectedPrinter } from '../../store/features/OTCSlice/OTCSlice';

type LocalStoragePrintersConfig = Record<string, string>;

const LOCAL_STORAGE_KEY = 'otc-printers';

export const getLocalStorageConfig = (): LocalStoragePrintersConfig => JSON.parse(
  window.localStorage.getItem(LOCAL_STORAGE_KEY) ?? '{}',
);

export const setLocalStorageConfig = (updatedConfig: LocalStoragePrintersConfig) => {
  try {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(updatedConfig),
    );
  } catch { /* do nothing */ }
};

export function useSetUserPrinterInLocalStorage() {
  const { username } = useGetAppState('coworkerAuth');

  return (id: string) => {
    if (!username) return;

    const localStoragePrinters = getLocalStorageConfig();
    setLocalStorageConfig({
      ...localStoragePrinters,
      [username]: id,
    });
  };
}

export function useUserPrinter() {
  const { username } = useGetAppState('coworkerAuth');
  const { data, isLoading } = useGetPrinters();
  const showSelectRecoveryLabelPrinter = useGoToSidebarPage('otc-select-recovery-label-printer');
  const setSelectedPrinter = useSetSelectedPrinter();

  const localStoragePrinters = getLocalStorageConfig();
  const userPrinterInLocalStorage = username ? localStoragePrinters[username] : null;

  useEffect(() => {
    if (!username) return;

    if (!userPrinterInLocalStorage) {
      showSelectRecoveryLabelPrinter();
      return;
    }

    if (isLoading) return;

    const { printers } = data ?? {};
    if (!printers || printers.length === 0) {
      showSelectRecoveryLabelPrinter();
      return;
    }

    const userPrinterExistInFetchedPrinters = printers.some(
      ({ name }) => name === userPrinterInLocalStorage,
    );
    if (userPrinterExistInFetchedPrinters) {
      setSelectedPrinter(userPrinterInLocalStorage);
      return;
    }

    const localStoragePrintersCopy = { ...localStoragePrinters };
    delete localStoragePrintersCopy[username];

    setLocalStorageConfig(localStoragePrintersCopy);
    showSelectRecoveryLabelPrinter();
  }, [data, isLoading, username]);
}
