import { useLazyGetProductInfoQuery } from '../store/features/api/apiSlice';
import { useMarketLocale } from './useMarketLocale';

export const useGetProductInfo = () => {
  const [getProductInfo] = useLazyGetProductInfoQuery();
  const { countryCode, languageCode } = useMarketLocale();

  return (productNumbers: string) => getProductInfo({
    countryCode, languageCode, productNumbers,
  });
};
