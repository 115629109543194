import type { AppEndPointBuilder } from './apiSlice';

type Printer = {
  name: string;
};

type PrinterResponse = {
  printers: Printer[];
};

type PrinterApiConfig = {
  buCode: string;
  countryCode: string;
};

export const getPrintersBuilder = (builder: AppEndPointBuilder) => builder.query<
PrinterResponse, PrinterApiConfig>({
  query: ({ buCode, countryCode }) => ({
    method: 'GET',
    url: `v1/${countryCode}/bu/${buCode}/printers`,
  }),
});
