import InlineMessage from '@ingka/inline-message';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { useTranslation } from 'react-i18next';
import { DataCard, DataCardConfig, DataCardConfigColumn } from 'shared-frontend';
import { CardTitle } from '../CardTitle/CardTitle';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { CustomerReturn } from '../../models';
import PreferredCommunicationOptions from './PreferredCommunicationOptions/PreferredCommunicationOptions';

type CustomerDetails = Pick<CustomerReturn, 'customer'>;

function CustomerDetailsDataCard() {
  const { t } = useTranslation();
  const content = useGetAppState('customerReturn');

  if (!content) return null;

  const billingAddressRows: DataCardConfig<CustomerDetails>[] = [{
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => [customer?.billingInfo.firstName, customer?.billingInfo.lastName].filter(Boolean).join(' '),
  },
  {
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => customer?.billingInfo.careOfName,
  },
  {
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => customer?.billingInfo.company,
  },
  {
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => customer?.billingInfo.addressLine1,
  },
  {
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => customer?.billingInfo.addressLine2,
  },
  {
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => customer?.billingInfo.addressLine3,
  },
  {
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => [customer?.billingInfo.postCode, customer?.billingInfo.city].filter(Boolean).join(' '),
  },
  {
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => [customer?.billingInfo.country, customer?.billingInfo.state, customer?.billingInfo.county].filter(Boolean).join(', '),
  },
  {
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => customer?.billingInfo.mobile,
  },
  {
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => customer?.billingInfo.phone,
  },
  {
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => customer?.billingInfo.email,
  }];

  const billingAddressRenderedRows = (): DataCardConfig<CustomerDetails>[] => {
    const billingInfoEmailMissing = !content.customer.billingInfo.email;

    const billingAddressEqualsDeliveryAddress = JSON.stringify(content.customer?.billingInfo)
      === JSON.stringify(content.customer?.deliveryInfo);

    if (billingInfoEmailMissing) {
      return [{
        accessor: 'customer',
        component: (
          <InlineMessage
            body={t('prompt-actions.missing-customer-information.missingBillingEmail')}
            className="break-normal"
            ssrIcon={informationCircle}
            subtle
            variant="negative"
          />
        ),
      }];
    }

    if (billingAddressEqualsDeliveryAddress) {
      return [{
        accessor: 'customer',
        valueRender: () => t('customer-details-data-card.billingSameAsDeliveryAddress'),
      }];
    }

    return billingAddressRows;
  };

  const firstColumnRows: DataCardConfig<CustomerDetails>[] = [{
    accessor: 'customer',
    valueRender: (customer: CustomerReturn['customer']) => (customer?.isBusinessCustomer
      ? t('customer-details-data-card.businessCustomer') : t('customer-details-data-card.privateCustomer')),
  }];
  content.customer?.loyaltyMemberships?.forEach(({ cardNumber, programCode }) => {
    const renderedProgramCode = t(
      `customer-details-data-card.loyaltyMembership.${programCode === 'FAMILY' ? 'family' : 'business'}`,
      { cardNumber },
    );

    firstColumnRows.push({
      accessor: 'customer',
      valueRender: () => renderedProgramCode,
    });
  });

  const config: DataCardConfigColumn<CustomerDetails>[] = [
    {
      title: [content.customer?.billingInfo?.firstName, content.customer?.billingInfo?.lastName].filter(Boolean).join(' '),
      ariaTitle: t('customer-details-data-card.screenReaderLabels.ariaTitle'),
      rows: firstColumnRows,
    },
    {
      title: t('customer-details-data-card.columnTitles.selectedCommunicationMethod'),
      rows: [{
        accessor: 'customer',
        valueRender: PreferredCommunicationOptions,
      }],
    },
    {
      title: t('customer-details-data-card.columnTitles.deliveryAddress'),
      rows: [{
        accessor: 'customer',
        valueRender: (customer: CustomerReturn['customer']) => customer?.deliveryInfo.company,
      },
      {
        accessor: 'customer',
        valueRender: (customer: CustomerReturn['customer']) => customer?.deliveryInfo.careOfName,
      },
      {
        accessor: 'customer',
        valueRender: (customer: CustomerReturn['customer']) => customer?.deliveryInfo.addressLine1,
      },
      {
        accessor: 'customer',
        valueRender: (customer: CustomerReturn['customer']) => customer?.deliveryInfo.addressLine2,
      },
      {
        accessor: 'customer',
        valueRender: (customer: CustomerReturn['customer']) => customer?.deliveryInfo.addressLine3,
      },
      {
        accessor: 'customer',
        valueRender: (customer: CustomerReturn['customer']) => [customer?.deliveryInfo?.postCode, customer?.deliveryInfo?.city].filter(Boolean).join(' '),
      },
      {
        accessor: 'customer',
        valueRender: (customer: CustomerReturn['customer']) => [customer?.deliveryInfo?.country, customer?.deliveryInfo?.state, customer?.deliveryInfo?.county].filter(Boolean).join(', '),
      }],
    },
    {
      title: t('customer-details-data-card.columnTitles.billingAddress'),
      rows: billingAddressRenderedRows(),
    },
  ];

  const ariaLabelledById = 'customer-details-title-id';

  return (
    <DataCard
      ariaLabelledById={ariaLabelledById}
      columnGrow={false}
      content={content}
      config={config}
      title={(
        <CardTitle id={ariaLabelledById}>
          {t('customer-details-data-card.title')}
        </CardTitle>
      )}
    />
  );
}

export default CustomerDetailsDataCard;
