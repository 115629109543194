import { useTranslation } from 'react-i18next';
import Statusbar from './Statusbar';
import StatusbarDraft from './StatusbarDraft';
import { useCreateRoute } from '../../hooks/useAppRoutes';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { ExchangeResolution } from './ExchangeResolution';

function StatusbarCard() {
  const isCreateRoute = useCreateRoute();
  const returnReference = useGetAppState('returnReference');
  const showDraftStatusbar = isCreateRoute && !returnReference;
  const StatusbarComponent = showDraftStatusbar ? StatusbarDraft : Statusbar;
  const { t } = useTranslation();
  const skipContentLabel = t('statusViewModel.steps.statusbar-carousel.skipStatusbar');

  return (
    <article
      aria-label={t('statusViewModel.articleAriaLabel')}
      className="px-2 m:px-6 l:px-10 pt-8 pb-6 border-y-neutral-grey-100 border-b-2"
    >
      <StatusbarComponent skipLabel={skipContentLabel} />
      <ExchangeResolution />
    </article>
  );
}

export default StatusbarCard;
