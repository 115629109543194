import {
  CompletedQuestionnaire,
  IsomReturnOption,
  mapQuestionnaire,
  NotificationChannel,
  SelectedReturnMethod,
} from 'shared-frontend';
import type {
  CustomerReturn,
  CustomerReturnItem,
  CustomerReturnRequest,
} from '../../../../models';
import { OutboundOrderReference, ReturnCustomer } from '../../../../models/CustomerReturnRequest';
import {
  ReturnArtItem,
  ReturnMissingArticleItem,
  ReturnDeliveryItem,
} from '../../../../models/CustomerReturn';
import { RefundCalcResponse } from '../../../../models/refundCalcResponse';
import { getArticleItemType } from '../../../../components/ArticlesReasonCard/ArticlesReasonCardUtils';
import {
  ExchangeResolutionOld,
  ExchangeResolutionNew,
  isOldExchangeResolution,
} from '../../../../models/Sac';

type PostBodyParams = {
  customerReturnContent: CustomerReturn;
  hasQuestionnaire: boolean;
  preferredCommunicationMethod: NotificationChannel;
  questionnaire: CompletedQuestionnaire | null;
  selectedReturnMethod: SelectedReturnMethod<IsomReturnOption>;
  refund: RefundCalcResponse;
  userId: string;
};

type ReturnItemsMapper = ReturnDeliveryItem | ReturnArtItem | ReturnMissingArticleItem;
const returnItemsMapper = (item: CustomerReturnItem): ReturnItemsMapper => {
  const baseItem = {
    itemNo: item.itemNo,
    itemType: item.itemType,
    quantity: item.quantity,
    price: item.price,
    originalPrice: item.originalPrice,
    sourceLineRef: item.sourceLineRef,
    ...(item.orderReference && { orderReference: item.orderReference }),
    ...(item.receiptReference && { receiptReference: item.receiptReference }),
  };

  const type = getArticleItemType(item);

  if (type === 'SERVICE_ITEM') {
    return baseItem as ReturnDeliveryItem;
  }

  const nonServiceItem = item as ReturnMissingArticleItem | ReturnArtItem;

  return {
    ...baseItem,
    ...(nonServiceItem.returnReason && { returnReason: nonServiceItem.returnReason }),
    ...(nonServiceItem.conditionOfGoods && { conditionOfGoods: nonServiceItem.conditionOfGoods }),
    ...(nonServiceItem.unit && { unit: nonServiceItem.unit }),
  } as typeof nonServiceItem;
};
const toLocalDateTimeWithoutOffset = (dateString: string) => {
  if (!dateString) return dateString;
  return dateString.replace(/([+-]\d{2}:\d{2}|Z)$/, '');
};

const outboundOrderReferenceForPayload = (
  exchangeResolution?: ExchangeResolutionOld | ExchangeResolutionNew,
): OutboundOrderReference | undefined => {
  if (!exchangeResolution) return undefined;

  if (isOldExchangeResolution(exchangeResolution)) {
    const { orderNumber, orderNumberSource } = exchangeResolution;
    return {
      orderNumber,
      orderNumberSource,
    };
  }

  if (!exchangeResolution.timeWindow) return exchangeResolution;

  return {
    ...exchangeResolution,
    timeWindow: {
      ...exchangeResolution.timeWindow,
      fromDateTime: toLocalDateTimeWithoutOffset(exchangeResolution.timeWindow.fromDateTime),
      toDateTime: toLocalDateTimeWithoutOffset(exchangeResolution.timeWindow.toDateTime),
    },
  };
};

export const formatBodyForApi = (postBodyParams: PostBodyParams): CustomerReturnRequest => {
  const {
    customerReturnContent,
    hasQuestionnaire,
    preferredCommunicationMethod,
    questionnaire,
    selectedReturnMethod,
    refund: refundSummary,
    userId,
  } = postBodyParams;

  const { customer, exchangeResolution } = customerReturnContent;
  const hasTaxationInfo = selectedReturnMethod.taxationInfo;
  const outboundOrderReference = outboundOrderReferenceForPayload(exchangeResolution);

  const body: CustomerReturnRequest = {
    causingBusinessUnit: customerReturnContent.causingBusinessUnit,
    createdBy: userId,
    customer: {
      ...customer,
      preferredCommunicationMethod,
    } as ReturnCustomer,
    returnAgreement: {
      returnRefundAmount: {
        refundSummary,
      },
      returnItems: customerReturnContent.items.map(returnItemsMapper),
      returnSettlement: {
        isCreditPayment: Boolean(customerReturnContent.isCreditPayment),
        numberOfLabels: customerReturnContent.numberOfLabels,
        paidBy: customerReturnContent.paidBy,
        returnOption: {
          id: selectedReturnMethod.id,
          labelOption: selectedReturnMethod.subOption?.key,
          originalPrice: selectedReturnMethod.originalPrice,
          price: selectedReturnMethod.price,
          deliveryLine: selectedReturnMethod.deliveryLine,
          ...(hasTaxationInfo && {
            taxationInfo: selectedReturnMethod.taxationInfo,
          }),
          timeWindow: {
            id: selectedReturnMethod.timeWindow.timeWindowId,
            fromDateTime: selectedReturnMethod.timeWindow.fromDateTime,
            toDateTime: selectedReturnMethod.timeWindow.toDateTime,
            timezone: selectedReturnMethod.timeWindow.timeZone,
            resourcePoolId: selectedReturnMethod.timeWindow.resourcePoolId,
            tspData: selectedReturnMethod.timeWindow.tspData,
          },
          ...(hasQuestionnaire && questionnaire?.questionnaire
            && mapQuestionnaire(questionnaire.questionnaire)
          ),
          ...(selectedReturnMethod.isPickUp && !hasQuestionnaire && {
            questionnaire: {
              questions: [],
              translatedType: '',
              type: '',
            },
          }),
        },
        outboundOrderReference,
      },
    },
    receivingBusinessUnit: selectedReturnMethod.receivingBusinessUnit,
    sourceReference: {
      type: 'SAMS',
      value: customerReturnContent.sacId,
    },
  };

  return body;
};
