import { useTranslation } from 'react-i18next';
import ArticleInput from '../ArticleInput/ArticleInput';
import { useMarketConfigFetch } from '../../../useMarketConfigFetch';
import { getArticleItemType } from '../ArticlesReasonCardUtils';
import { ArticleListItemProps } from '../types';
import {
  ReceivedArticleItem,
  ReturnMissingArticleItem,
  ReturnArtItem,
} from '../../../models/CustomerReturn';

interface ItemInputProps {
  article: ArticleListItemProps;
}

function ItemInput({ article }: ItemInputProps) {
  const { t } = useTranslation();
  const { data: marketConfig } = useMarketConfigFetch();
  const type = getArticleItemType(article);

  if (type === 'SERVICE_ITEM' || type === 'UNEXPECTED_ITEM' || !marketConfig?.returnReasons.reasonCodes) {
    return null;
  }

  const {
    quantity, returnReason: { mainReasonCode, subReasonCode }, unit,
  } = article as (ReturnMissingArticleItem | ReturnArtItem | ReceivedArticleItem);

  const configReasonCodes = marketConfig.returnReasons.reasonCodes;

  const reasonCodesTranslation: Record<string, string> = configReasonCodes
    .reduce((acc, reason) => ({
      ...acc,
      ...reason.subReasons.reduce((innerAcc, subReason) => {
        const translation = t(`articles-reason-card.reason-codes.${subReason.titleTranslationId}`);
        const translationKey = `${reason.code}${subReason.code}`;
        return (
          {
            ...innerAcc,
            [translationKey]: translation || `${reason.code}-${subReason.code}`,
          }
        );
      }, {}),
    }), {});

  const itemCode = `${mainReasonCode}${subReasonCode}`;
  const codeTranslation = reasonCodesTranslation[itemCode];
  const value = `${itemCode}${codeTranslation ? ` ${codeTranslation}` : ''}`;

  return (
    <div className="flex flex-col justify-start gap-2">
      <ArticleInput
        ariaLabel={`${t('articles-reason-card.aria-labels.reasonInput')}: ${value}`}
        value={value}
        className="order-2 m:order-1"
      />

      <div className="flex items-center order-1 m:order-2">
        <div className="mr-2">{t('articles-reason-card.quantity')}</div>
        <span
          data-testid="article-quantity"
          className="px-2 rounded-sm border-[1px] border-lightest bg-white"
          aria-describedby={unit === 'Meters' ? `${t('articles-reason-card.aria-labels.unitMetersInput')}: ${value}` : ''}
        >
          {quantity}
        </span>
      </div>
    </div>
  );
}

export default ItemInput;
