import {
  formatToLongDate, Statusbar, StatusbarStep, stepViewModelMapper,
  StatusViewModel, StepViewModel,
} from 'shared-frontend';
import { useTranslation } from 'react-i18next';
import { useMarketLocale } from '../../hooks/useMarketLocale';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';

function StatusbarDraft({ skipLabel }: { skipLabel?: string }) {
  const { t } = useTranslation();
  const date = new Date();
  const { countryCode, languageCode } = useMarketLocale();
  const localizedDate = formatToLongDate({ date, countryCode, languageCode });
  const customerReturn = useGetAppState('customerReturn');

  const statusViewModel: StatusViewModel = [
    {
      details: [
        localizedDate,
        `${customerReturn ? t('statusViewModel.steps.DRAFT.details.sac', { sourceReference: customerReturn.sacId }) : ''}`,
      ],
      step: 'DRAFT' as StepViewModel['step'],
      stepStatus: 'SUCCESS',
      title: t('statusViewModel.steps.DRAFT.title'),
      tooltipText: t('statusViewModel.steps.DRAFT.tooltip'),
    },
    {
      details: [],
      step: 'CREATED',
      stepStatus: 'NONE',
      title: t('statusViewModel.steps.CREATED.title'),
      tooltipText: t('statusViewModel.steps.CREATED.tooltip'),
    },
  ];

  const statusbarSteps: StatusbarStep[] = statusViewModel.map(stepViewModelMapper);

  return (
    <Statusbar
      steps={statusbarSteps}
      skipLabel={skipLabel}
      ariaLabelLeftBtn={t('statusViewModel.steps.statusbar-carousel.ariaPrevious')}
      ariaLabelRightBtn={t('statusViewModel.steps.statusbar-carousel.ariaNext')}
    />
  );
}

export default StatusbarDraft;
