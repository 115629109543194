import { SacItem } from './SacItem';

export interface BusinessUnit {
  type: string,
  code: string,
}

export interface CustomerInfo {
  company?: string,
  firstName?: string,
  lastName?: string,
  middleName?: string,
  secondLastName?: string,
  addressLine1?: string,
  addressLine2?: string,
  addressLine3?: string,
  country?: string,
  city?: string,
  postCode?: string,
  state?: string,
  county?: string,
  email: string,
  phone?: string,
  mobile?: string,
  careOfName?: string,
  taxRegNo?: string,
  taxIdType?: string,
  foreignCountry?: string,
  organisationNumber?: string,
  subDivisionIsoCode?: string,
  employeeId?: string,
  taxRegistrationCountryIsoCode?: string,
  additionalTaxRegNo?: string,
  additionalTaxIdType?: string,
  taxPayeeType?: string,
  organisationNumberType?: string,
  legalEntityNumber?: string,
  legalEntityNumberType?: string,
}

export const programCodeValues = ['BUSINESS', 'FAMILY'] as const;
export type ProgramCode = typeof programCodeValues[number];

export type LoyaltyMembership = {
  cardNumber: string,
  programCode: ProgramCode,
};

interface Customer {
  globalCustomerId?: string,
  isBusinessCustomer: boolean,
  preferredLanguage: string,
  deliveryInfo: CustomerInfo,
  billingInfo: CustomerInfo,
  loyaltyMemberships?: LoyaltyMembership[],
}

export type UserId = string;

export type ExchangeResolutionOld = {
  deliveryFromDateTime?: string;
  deliveryToDateTime?: string;
  orderNumber: string;
  orderNumberSource: string;
};

export type ExchangeResolutionNew = {
  likeForLike?: boolean;
  orderNumber: string;
  orderNumberSource: string;
  timeWindow?: {
    fromDateTime: string;
    id: string;
    toDateTime: string;
  };
  tspData?: {
    id: string;
    name: string;
  };
};

export const isOldExchangeResolution = (
  exchangeResolution: ExchangeResolutionOld | ExchangeResolutionNew,
): exchangeResolution is ExchangeResolutionOld => 'deliveryFromDateTime' in exchangeResolution;

export interface Sac {
  auth: {
    accessToken: string,
    userId: UserId,
  }
  sacId: string,
  causingBusinessUnit: BusinessUnit,
  numberOfLabels: number,
  paidByIkea: boolean,
  paidByTsp?: boolean,
  isCredit: boolean,
  items: Array<SacItem>,
  customer: Customer,
  exchangeResolution?: ExchangeResolutionOld | ExchangeResolutionNew,
}
